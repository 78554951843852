<template>
  <svg
    width="96"
    height="97"
    viewBox="0 0 96 97"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.16" cx="48" cy="48.5" r="48" fill="black" />
    <circle cx="48" cy="48.5" r="32" fill="white" />
    <path
      d="M42.25 37.25L59.75 48.5L42.25 59.75V37.25Z"
      stroke="black"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
